import { COLORS } from '../theme';

export const dateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const getRemainingTicketsText = (availableTickets) => {
  if (isNaN(availableTickets)) {
    return availableTickets;
  }

  switch (true) {
    case +availableTickets === 0: {
      return <span style={{ color: COLORS.soldOut }}>Sold Out</span>;
    }
    case availableTickets < 10: {
      return <div style={{textAlign: "center"}}>
        Tickets Left
        <div style={{fontWeight: "bold"}}>{availableTickets}</div>
      </div>;
    }
    default: {
      return <div style={{textAlign: "center"}}>
        Tickets Left
        <div style={{fontWeight: "bold"}}>10+</div>
      </div>;
    }
  }
};
